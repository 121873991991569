import {
  Button,
  ButtonVariant,
  Card,
  Modal,
  ModalContent,
  ModalHeader,
  ModalMain,
  ModalTitle,
  Text,
} from '@tenset/components'
import { useEffect, useState } from 'react'
import { useTypedRouteLoaderData } from 'remix-typedjson'
import { useSignMessage } from 'wagmi'

import { ConnectWalletButton } from '../connect-wallet'

import {
  fetchAgreementMessage,
  fetchWalletAgreement,
  postWalletAgreement,
} from '~/api/fetchers/.client'
import type { RootLoaderData } from '~/root'

export function AcceptTermsModal() {
  const { sessionAddress } = useTypedRouteLoaderData<RootLoaderData>('root')!

  const { isPending, signMessageAsync } = useSignMessage()

  const [showAcceptTermsModal, setShowAcceptTermsModal] = useState(false)

  useEffect(() => {
    if (!sessionAddress) return setShowAcceptTermsModal(false)

    fetchWalletAgreement(sessionAddress).then(agreement => {
      setShowAcceptTermsModal(!agreement)
    })
  }, [sessionAddress])

  const signAgreement = async () => {
    if (!sessionAddress || isPending) return

    const agreementMessage = await fetchAgreementMessage()

    const currentDate = new Date().toLocaleString('en-US', {
      timeZone: 'UTC',
    })

    const agreement = [
      agreementMessage,
      `Wallet address: ${sessionAddress}`,
      `Date: ${currentDate} UTC`,
    ]

    const message = agreement.join('\n\n')

    const signature = await signMessageAsync({ message }).catch(() => '')

    if (!signature) return

    await postWalletAgreement(sessionAddress, message, signature)

    setShowAcceptTermsModal(false)
  }

  const termsConditionsUrl =
    'https://www.tenset.io/assets/documents/tenset-terms-and-conditions.pdf'
  const privacyCookiesPolicyUrl =
    'https://www.tenset.io/assets/documents/tenset-privacy-and-cookies-policy.pdf'

  return (
    <Modal open={showAcceptTermsModal}>
      <ModalContent withXCloseButton={false}>
        <ModalHeader className="sr-only">
          <ModalTitle>Tenset Platform Terms</ModalTitle>
        </ModalHeader>

        <ModalMain>
          <Card
            title="Tenset Platform Terms"
            action={
              <div className="flex flex-col gap-2 sm:flex-row">
                <Button
                  onClick={signAgreement}
                  loading={isPending}
                  disabled={isPending}
                >
                  Accept and sign
                </Button>

                <ConnectWalletButton />
              </div>
            }
            limitWidth={false}
          >
            <Text>
              By connecting your wallet and using Tenset Platform, you agree to
              our&nbsp;
              <Button variant={ButtonVariant.Link} to={termsConditionsUrl}>
                Terms & Conditions
              </Button>
              &nbsp;and&nbsp;
              <Button variant={ButtonVariant.Link} to={privacyCookiesPolicyUrl}>
                Privacy & Cookies policy
              </Button>
              .
            </Text>
          </Card>
        </ModalMain>
      </ModalContent>
    </Modal>
  )
}
