import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useRouteError,
} from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  Button,
  ButtonVariant,
  DataPoint,
  Footer,
  Hero,
  HeroImage,
  IconName,
  Navigation,
} from '@tenset/components'
import { watchAccount } from '@wagmi/core'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import {
  typedjson,
  useTypedLoaderData,
  useTypedRouteLoaderData,
} from 'remix-typedjson'
import { WagmiProvider } from 'wagmi'

import {
  ConnectWalletButton,
  ConnectWalletModal,
} from './components/connect-wallet'
import { footerLists } from './data'
import { useNavigationData } from './hooks'
import { getWagmiConfig, initializeWagmiConfig } from './wagmi-config'

import { ErrorIllustration } from '~/assets/images'
import { AcceptTermsModal, PageLoadingIndicator } from '~/components/utils'
import { getClientEnvironment } from '~/environment/environment.server'
import { getSession } from '~/sessions.server'
import globalStylesheet from '~/styles/global.css?url'
import libsStylesheet from '~/styles/libs.css?url'
import { getErrorBoundaryData } from '~/utils'

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get('Cookie'))

  const sessionAddress = session.get('sessionAddress')

  const env = getClientEnvironment()

  return typedjson({ sessionAddress, env })
}

export type RootLoaderData = typeof loader

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: libsStylesheet },
  { rel: 'stylesheet', href: globalStylesheet },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png',
  },
  { rel: 'manifest', href: '/site.webmanifest' },
  { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#671cc1' },
]

export const meta: MetaFunction = () => {
  return [{ title: 'Tenset Infinity Airdrop' }]
}

interface DocumentProps {
  children: ReactNode
}

const localeData = {
  supportedLocales: [],
  currentLocale: '',
  changePathnameLocale: (locale: string) => locale,
  routeWithLocale: (locale: string) => locale,
}

export function Layout({ children }: DocumentProps) {
  const data = useTypedRouteLoaderData<RootLoaderData>('root')

  /**
   * Remix modules cannot have side effects
   * so the initialization of `wagmi` client happens during render,
   * but the result is cached via `useState` and a lazy initialization function
   * @see https://remix.run/docs/en/main/guides/constraints#no-module-side-effects
   */
  const [config] = useState(initializeWagmiConfig)
  const [queryClient] = useState(() => new QueryClient())

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Largest airdrop pool in crypto. Investors can diversify their digital portfolio and earn passive income with rewards from tens of different tokens daily."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#26232b" />
        <Meta />
        <Links />
      </head>

      <body>
        {config && (
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <LayoutBody>{children}</LayoutBody>
            </QueryClientProvider>
          </WagmiProvider>
        )}

        <ScrollRestoration />
        {data ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.env = ${JSON.stringify(data.env)}`,
            }}
          />
        ) : null}
        <Scripts />
      </body>
    </html>
  )
}

function LayoutBody({ children }: DocumentProps) {
  const { navigationData } = useNavigationData()

  return (
    <div className="font-poppins flex min-h-screen flex-col bg-neutral-900 text-white">
      <header>
        <Navigation
          data={navigationData}
          localeData={localeData}
          rightContent={<ConnectWalletButton />}
          bottomContent={<PageLoadingIndicator />}
          logoLink="https://tenset.io"
        />
      </header>

      <main className="flex-grow">{children}</main>

      <Footer
        icon={IconName.InfinityFooter}
        lists={footerLists}
        className="lg:!flex-row"
      >
        <Button variant={ButtonVariant.Link} to="/ambassadors">
          Our ambassadors
        </Button>
      </Footer>

      <ConnectWalletModal />
      <AcceptTermsModal />
    </div>
  )
}

function App() {
  const fetcher = useFetcher()
  const { sessionAddress } = useTypedLoaderData<RootLoaderData>()

  const config = getWagmiConfig()

  useEffect(() => {
    return watchAccount(config, {
      onChange({ address }) {
        if (!address && sessionAddress) {
          fetcher.submit(
            {},
            {
              method: 'post',
              action: '/api/wallet/disconnect',
            }
          )

          return
        }

        if (!address) return

        if (address.toLowerCase() !== sessionAddress) {
          fetcher.submit(
            {},
            {
              method: 'post',
              action: `/api/wallet/connect?sessionAddress=${address}`,
            }
          )
        }
      },
    })
  }, [sessionAddress])

  useEffect(() => {
    Sentry.setUser(
      sessionAddress
        ? {
            id: sessionAddress,
          }
        : null
    )
  }, [sessionAddress])

  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  const CONTACT_URL = 'https://www.tenset.io/contact'

  const { message, stack } = getErrorBoundaryData(error)

  return (
    <div className="container">
      <Hero
        title="An error has occurred!"
        description={message}
        rightContent={<HeroImage src={ErrorIllustration} alt="Error" />}
        leftContent={
          <div className="flex flex-col gap-4">
            {stack && <DataPoint label="Stack">{stack}</DataPoint>}

            <div>
              <Button to={CONTACT_URL} variant={ButtonVariant.Primary}>
                Contact us
              </Button>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default withSentry(App)
