import {
  Button,
  ButtonVariant,
  cn,
  H3,
  Icon,
  IconName,
  Image,
  Modal,
  ModalContent,
  ModalHeader,
  ModalMain,
  ModalTitle,
} from '@tenset/components'
import { useEffect } from 'react'
import { useTypedRouteLoaderData } from 'remix-typedjson'
import type { Connector } from 'wagmi'
import { useAccount, useConnect } from 'wagmi'

import { useConnectWalletModal } from '~/hooks'
import type { RootLoaderData } from '~/root'

export function ConnectWalletModal() {
  const { sessionAddress } = useTypedRouteLoaderData<RootLoaderData>('root')!

  const { isOpen, hideModal, onConnect } = useConnectWalletModal()
  const { connectors: _connectors, connect } = useConnect()
  const { isConnected } = useAccount()

  const connectors = _connectors
    // remove default injected connector
    .filter(({ name }) => name !== 'Injected')
    // MetaMask first, WalletConnect last
    .sort((a, b) => {
      if (a.name === 'MetaMask') return -1
      if (b.name === 'MetaMask') return 1
      if (a.name === 'WalletConnect') return 1
      if (b.name === 'WalletConnect') return -1
      return 0
    })

  useEffect(() => {
    if (!isConnected || !sessionAddress) return

    onConnect?.()
    hideModal()
  }, [isConnected, sessionAddress])

  return (
    <Modal open={isOpen} onClose={hideModal}>
      <ModalContent withPadding={false}>
        <ModalHeader className="sr-only">
          <ModalTitle>Connect wallet</ModalTitle>
        </ModalHeader>

        <ModalMain
          className={cn(
            connectors.length > 1 && 'grid grid-cols-2 place-items-center'
          )}
        >
          {connectors.map(connector => {
            const { uid, name } = connector

            return (
              <Button
                variant={ButtonVariant.Ghost}
                className="flex w-full h-full flex-col-reverse gap-4 py-3 md:py-6"
                key={uid}
                onClick={() => {
                  connect({ connector })
                }}
              >
                <H3 isBold>{name}</H3>

                <ConnectorIcon connector={connector} />
              </Button>
            )
          })}
        </ModalMain>
      </ModalContent>
    </Modal>
  )
}

type ConnectorIcons = Record<string, IconName>

const connectorIcons: ConnectorIcons = {
  WalletConnect: IconName.WalletConnect,
  MetaMask: IconName.MetaMask,
  'OKX Wallet': IconName.OKX,
}

interface ConnectorIconProps {
  connector: Connector
}

function ConnectorIcon({ connector }: ConnectorIconProps) {
  const { name, icon } = connector

  if (name in connectorIcons) {
    return <Icon name={connectorIcons[name]} size={40} />
  }

  if (icon) {
    return <Image src={icon} alt={name} width="40px" height="auto" />
  }

  return null
}
