import { Agreement } from '~/api/types'

export async function fetchAgreementMessage(): Promise<string> {
  const response = await fetch(window.env.AGREEMENTS_API_URL)
  const { message } = await response.json()

  return message
}

export async function fetchWalletAgreement(
  account: string
): Promise<Agreement | null> {
  const response = await fetch(`${window.env.AGREEMENTS_API_URL}/${account}`)
  const { agreement } = await response.json()

  return agreement
}

export async function postWalletAgreement(
  account: string,
  message: string,
  signature: string
): Promise<boolean> {
  const response = await fetch(window.env.AGREEMENTS_API_URL, {
    method: 'POST',
    body: JSON.stringify({ wallet: account, message, signature }),
  })
  const { message: message_1 } = await response.json()

  return message_1
}
