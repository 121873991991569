import { useNavigate } from '@remix-run/react'
import type { NavigationData } from '@tenset/components'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { useConnectWalletModal } from './use-connect-wallet-modal'

import { navigationDataFactory } from '~/data'

export function useNavigationData() {
  const { isConnected } = useAccount()
  const navigate = useNavigate()
  const connectWalletModal = useConnectWalletModal()

  const [navigationData, setNavigationData] = useState<NavigationData[]>(
    navigationDataFactory(isConnected, connectWalletModal, navigate)
  )

  useEffect(() => {
    setNavigationData(
      navigationDataFactory(isConnected, connectWalletModal, navigate)
    )
  }, [isConnected])

  return {
    navigationData,
  }
}
