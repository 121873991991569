import { isRouteErrorResponse } from '@remix-run/react'

function getErrorBoundaryMessage(error: unknown) {
  if (isRouteErrorResponse(error)) return `${error.status} ${error.statusText}`

  if (error instanceof Error) return error.message

  return 'Unknown Error'
}

function getErrorBoundaryStack(error: unknown) {
  if (isRouteErrorResponse(error)) return error.data

  if (error instanceof Error) return error.stack
}

export function getErrorBoundaryData(error: unknown) {
  return {
    message: getErrorBoundaryMessage(error),
    stack: getErrorBoundaryStack(error),
  }
}
