import type { NavigateFunction } from '@remix-run/react'
import type { NavigationData } from '@tenset/components'
import { HrefType, isExternalLink } from '@tenset/components'

import type { ConnectWalletModalContextType } from '~/context'

interface Route {
  label: string
  url: string
  isLoginProtected?: boolean
}

const routes: Route[] = [
  {
    label: 'Tenset IO',
    url: 'https://tenset.io',
  },
  {
    label: 'About',
    url: '/',
  },
  {
    label: 'Withdrawals',
    url: '/withdrawals',
    isLoginProtected: true,
  },
  {
    label: 'My Locks',
    url: '/locks',
    isLoginProtected: true,
  },
  {
    label: 'Lock More',
    url: '/locks/create',
  },
  {
    label: 'Holders',
    url: '/holders',
  },
  {
    label: '10SETx',
    url: '/tenset-x',
  },
]

export const navigationDataFactory = (
  isConnected = false,
  connectWalletModal: ConnectWalletModalContextType,
  navigate: NavigateFunction
): NavigationData[] => {
  function getRouteData({
    label,
    url,
    isLoginProtected,
  }: Route): NavigationData {
    const notConnectedFunction = () => {
      connectWalletModal.updateOnConnect(() => navigate(url))

      connectWalletModal.showModal()
    }

    const hrefType = isExternalLink(url) ? HrefType.External : HrefType.Internal

    const buttonAction =
      isLoginProtected && !isConnected
        ? { onClick: notConnectedFunction }
        : { href: { type: hrefType, url, withLocale: false } }

    return {
      ...buttonAction,
      label,
    }
  }

  return routes.map(route => getRouteData(route))
}
