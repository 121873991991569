import type { ListItem } from '@tenset/components'
import { IconName } from '@tenset/components'

const {
  TwitterMono,
  TelegramCommunity,
  TelegramAnnouncements,
  LinkedinMono,
  CoinGeckoMono,
  LineMono,
  KakaoTalkMono,
  YoutubeMono,
  Verification,
} = IconName

export const footerLists: ListItem[] = [
  {
    title: 'Community',
    elements: [
      {
        name: 'Twitter',
        to: 'https://twitter.com/Tenset_io',
        icon: TwitterMono,
      },
      {
        name: 'Twitter Security',
        to: 'https://twitter.com/tenset_security',
        icon: TwitterMono,
      },
      {
        name: 'Community',
        to: 'https://t.me/tenset_io_eng',
        icon: TelegramCommunity,
      },
      {
        name: 'Announcements',
        to: 'https://t.me/tensetio',
        icon: TelegramAnnouncements,
      },
      {
        name: 'LinkedIn',
        to: 'https://www.linkedin.com/company/tenset',
        icon: LinkedinMono,
      },
      {
        name: 'CoinGecko',
        to: 'https://www.coingecko.com/en/coins/tenset',
        icon: CoinGeckoMono,
      },
      {
        name: 'Line',
        to: 'https://line.me/ti/g2/QBYVIHDNVY_InQzC-r7kjw',
        icon: LineMono,
      },
      {
        name: 'KakaoTalk',
        to: 'https://open.kakao.com/o/gIQdeT2c',
        icon: KakaoTalkMono,
      },
      {
        name: 'YouTube',
        to: 'https://www.youtube.com/channel/UC5RxXdhQ6BkbB4lfTMkWGhA',
        icon: YoutubeMono,
      },
      {
        name: 'Verify Media',
        to: 'https://tenset.io/verification',
        icon: Verification,
      },
    ],
  },
  {
    title: 'Apply',
    elements: [
      {
        name: 'Apply to Infinity',
        to: 'https://docs.google.com/forms/d/12PxyUm5Vzl0Sj1RT13MZtJAlqkescvzjiTZSYLL3d4M/viewform?edit_requested=true',
      },
      {
        name: 'Apply to TGLP',
        to: 'https://docs.google.com/forms/d/e/1FAIpQLSfBlskPKMghGW5vtdZzGO8j1yZzMJKlj9JMAFe8QSJeGEQW7A/viewform',
      },
    ],
  },
  {
    title: 'Legal',
    elements: [
      {
        name: 'Terms & Conditions',
        to: 'https://tenset.io/assets/documents/tenset-terms-and-conditions.pdf',
      },
      {
        name: 'Privacy & Cookies policy',
        to: 'https://tenset.io/assets/documents/tenset-privacy-and-cookies-policy.pdf',
      },
    ],
  },
]
